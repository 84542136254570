<template>
  <SummarySection>
    <p v-if="loan.incomes">
      Para una vivienda de
      <strong>{{ toSpanishFormat(loan.collateralPrice) }}€</strong>, una
      aportación de
      <strong>{{ toSpanishFormat(loan.depositAmount) }}€</strong> y unos
      ingresos mensuales de
      <strong>{{ toSpanishFormat(loan.incomes) }}€</strong>.
    </p>
    <p v-else>
      Para una vivienda de
      <strong>{{ toSpanishFormat(loan.collateralPrice) }}€</strong> y una
      aportación de <strong>{{ toSpanishFormat(loan.depositAmount) }}€</strong>.
    </p>
  </SummarySection>
  <div v-if="!Object.keys(products).length">
    <h4>Lo sentimos, no hay resultados para mostrar.</h4>
    <router-link
      v-if="lastPagePath"
      class="primary-btn go-back-btn"
      style="padding: 0 16px"
      :to="{ path: lastPagePath, query: lastPageQueryParams }"
    >
      Volver al formulario</router-link
    >
  </div>
  <div v-else-if="!!orderedProducts.length">
    <div v-if="hasAnyViableProduct">
      <div class="mortgage-info">
        <p>
          Con los datos que nos has facilitado, éstos son los productos
          hipotecarios
          <strong>a {{ loan.maturity }} años</strong> que hemos encontrado para
          ti:
        </p>
      </div>
      <ul class="product-list container">
        <li v-for="products in orderedProducts" :key="products">
          <ProductMock
            v-for="product in products"
            :key="product"
            :product="product"
            @open="openModal"
          ></ProductMock>
        </li>
      </ul>
    </div>
    <div v-else>
      <div class="indebtedness-fail container content-container">
        <div>
          <p>
            ¡Ops! Con los datos que nos has facilitado tu nivel de endeudamiento
            es superior al
            <strong>{{ minIndebtedness }}%</strong>.
          </p>
          <p>
            Para poder disponer de una hipoteca, la cuota mensual de la misma no
            puede superar el {{ indebtednessLimit }}% de los ingresos de los
            partícipes en la hipoteca.
          </p>
          <h3>¿Qué opciones tenemos?</h3>
        </div>

        <div>
          <ul style="list-style: auto">
            <li class="mb5">Mejorar los datos en la simulación:</li>
            <ul>
              <li>
                Aumentar tu aportación (lo ideal es que aportes el 20-25% del
                importe de compra de la vivienda).
              </li>
              <li>Agregar otro titular a la hipoteca y sumar los ingresos.</li>
              <li>Reducir el importe de la vivienda que quieres adquirir.</li>
            </ul>
            <router-link
              v-if="lastPagePath"
              class="primary-btn go-back-btn"
              style="margin-top: 20px; padding: 0 16px"
              :to="{ path: lastPagePath, query: lastPageQueryParams }"
            >
              Modificar y volver a calcular</router-link
            >
            <li style="margin-top: 15px" class="mb5">
              Contactar con nosotros:
            </li>
            <ul style="list-style: none">
              <li class="link-with-icon">
                <img src="@/assets/img/phone-solid.svg" />
                <a href="tel:+34602259415" target="_blank">602 25 94 15</a>
              </li>
              <li class="link-with-icon">
                <img src="@/assets/img/envelope-solid.svg" />
                <a href="mailto:hola@prohipotecas.com">hola@prohipotecas.com</a>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="indebtedness-fail container content-container">
    <div>
      <p>
        ¡Ops! Con los datos que nos has facilitado tu nivel de endeudamiento es
        superior al
        <strong>{{ minIndebtedness }}%</strong>.
      </p>
      <p>
        Para poder disponer de una hipoteca, la cuota mensual de la misma no
        puede superar el {{ indebtednessLimit }}% de los ingresos de los
        partícipes en la hipoteca.
      </p>
      <h3>¿Qué opciones tenemos?</h3>
    </div>

    <div>
      <ul style="list-style: auto">
        <li class="mb5">Mejorar los datos en la simulación:</li>
        <ul>
          <li>
            Aumentar tu aportación (lo ideal es que aportes el 20-25% del
            importe de compra de la vivienda).
          </li>
          <li>Agregar otro titular a la hipoteca y sumar los ingresos.</li>
          <li>Reducir el importe de la vivienda que quieres adquirir.</li>
        </ul>
        <router-link
          v-if="lastPagePath"
          class="primary-btn go-back-btn"
          style="margin-top: 20px; padding: 0 16px"
          :to="{ path: lastPagePath, query: lastPageQueryParams }"
        >
          Modificar y volver a calcular</router-link
        >
        <li style="margin-top: 15px" class="mb5">Contactar con nosotros:</li>
        <ul style="list-style: none">
          <li class="link-with-icon">
            <img src="@/assets/img/phone-solid.svg" />
            <a href="tel:+34602259415" target="_blank">602 25 94 15</a>
          </li>
          <li class="link-with-icon">
            <img src="@/assets/img/envelope-solid.svg" />
            <a href="mailto:hola@prohipotecas.com">hola@prohipotecas.com</a>
          </li>
        </ul>
      </ul>
    </div>
  </div>

  <Modal :showModal="showModal" :cssClass="'center-modal'" @close="hideModal">
    <template v-slot:body>
      <h3>Estamos transfiriendo tu contacto a una analista</h3>
      <img src="@/assets/img/ok-icon.png" class="ok-icon" />
      <p>
        Estamos asignando tu contacto a una de nuestras analistas, que se pondrá
        en contacto contigo lo antes posible y te ayudará a avanzar con tu
        hipoteca.
      </p>
      <p>
        <strong
          >Si lo prefieres, puedes
          <a
            @click.prevent.capture="doThis"
            href="https://calendly.com/finteca/primera-llamada"
            rel="noopener noreferrer"
            target="_blank"
            >agendar una llamada haciendo click aquí</a
          >.</strong
        >
      </p>
    </template>
  </Modal>
</template>

<script>
import { defineAsyncComponent, ref, toRefs, computed } from 'vue';
import { toSpanishFormat } from '../composables/utils';

export default {
  name: 'ProductMocksList',
  components: {
    SummarySection: defineAsyncComponent(() =>
      import('@/components/SummarySection.vue'),
    ),
    ProductMock: defineAsyncComponent(() =>
      import('@/components/ProductMock.vue'),
    ),
    Modal: defineAsyncComponent(() => import('@/components/Modal.vue')),
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const showModal = ref(false);
    const { products } = toRefs(props);
    const lastPageQueryParams = JSON.parse(
      sessionStorage.getItem('queryParams'),
    );

    const lastPagePath = JSON.parse(sessionStorage.getItem('path'));
    let viableProductsCount = 0;
    const indebtednessLimit = parseFloat(
      process.env.VUE_APP_INDEBTEDNESS_LIMIT,
    );

    const doThis = () => {
      window
        .open('https://calendly.com/finteca/primera-llamada', '_blank')
        .focus();
    };

    const minIndebtedness = Math.min(
      ...[]
        .concat(...Object.values(products.value))
        .map((p) => p.rates.indebtedness),
    );

    Object.values(products.value).forEach((e) => {
      viableProductsCount += e.filter(
        (p) => p.rates.indebtedness <= indebtednessLimit,
      ).length;
    });

    const hasAnyViableProduct = viableProductsCount > 0;

    const openModal = (e) => {
      const element = e.target;
      element.className += ' disabled';
      element.disabled = true;
      showModal.value = true;
    };

    const hideModal = () => {
      showModal.value = false;
    };

    const orderByOutbountUrlAndPayment = (a, b) => {
      if (a.outboundUrl || b.outboundUrl) {
        if (a.outboundUrl && !b.outboundUrl) {
          return -1;
        }
        if (!a.outboundUrl && b.outboundUrl) {
          return 1;
        }
        return a.rates.payment > b.rates.payment ? 1 : -1;
      }

      return a.rates.payment > b.rates.payment ? 1 : -1;
    };

    //  Order products of each bank by outboundUrl and lower rate
    let orderedProducts = computed(() => {
      Object.entries(products.value).forEach(([key, value]) => {
        products.value[key] = value
          .filter((p) => p.rates.indebtedness <= indebtednessLimit)
          .sort(orderByOutbountUrlAndPayment);
      });
      return products.value;
    });

    //  Order products by bank (by outboundUrl and lower rate)
    orderedProducts = Object.values(orderedProducts.value)
      .filter((o) => o.length)
      .sort((a, b) => {
        if (a[0].outboundUrl || b[0].outboundUrl) {
          if (a[0].outboundUrl && !b[0].outboundUrl) {
            return -1;
          }
          if (!a[0].outboundUrl && b[0].outboundUrl) {
            return 1;
          }
          return a[0].rates.payment > b[0].rates.payment ? 1 : -1;
        }

        return a[0].rates.payment > b[0].rates.payment ? 1 : -1;
      });

    return {
      hasAnyViableProduct,
      minIndebtedness,
      indebtednessLimit,
      doThis,
      showModal,
      orderedProducts,
      lastPageQueryParams,
      lastPagePath,
      toSpanishFormat,
      openModal,
      hideModal,
    };
  },
};
</script>

<style scoped lang="scss">
.product-list {
  list-style: none;
  padding: 0;
}

.ok-icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.mortgage-info {
  padding: 0 20px;
}

.go-back-btn {
  margin: 0 auto;
  width: max-content !important;
}

.indebtedness-fail {
  text-align: left;

  li {
    margin-bottom: 10px;
  }
}

.link-with-icon {
  display: flex;
  gap: 10px;

  img {
    width: 15px;
  }
}
</style>
