<template>
  <div>
    <SummarySection>
      <p>
        <strong>Paso {{ currentStep + 1 }} de {{ stepCount }}</strong
        >: {{ steps[currentStep]?.title }}
      </p>
    </SummarySection>

    <div class="container form-section">
      <Form @submit="nextStep" class="content-container">
        <Field
          v-model="website"
          :name="'website'"
          :rules="'minLength:5'"
        ></Field>

        <div class="website-validation-message">
          <ErrorMessage :name="'website'" />
        </div>

        <transition-group name="list" tag="div">
          <div
            v-for="component in steps[currentStep]?.components || []"
            :key="component"
          >
            <keep-alive>
              <component
                :is="component.name"
                :options="component.values"
                :title="
                  component.alternativeTitle &&
                  params[component.alternativeTitle.param] ===
                    component.alternativeTitle.value
                    ? component.alternativeTitle.title
                    : component.title
                "
                :placeholder="
                  component.alternativePlaceholder &&
                  params[component.alternativePlaceholder.param] ===
                    component.alternativePlaceholder.value
                    ? component.alternativePlaceholder.placeholder
                    : component.placeholder
                "
                :name="component.outputVar"
                :rules="component.validationRules"
                :mask="component.mask"
                :cssClass="component.cssClass"
                v-model="params[component.outputVar]"
                @updated="onUpdated"
              >
              </component>
            </keep-alive>
          </div>

          <div v-if="isLast" :key="'rgpd-checkbox'" class="checkbox-container">
            <RGPD
              @rgpd="setRGPD"
              @privacy="modal.privacy = true"
              @terms="modal.terms = true"
            />
          </div>

          <div class="step-buttons" :key="currentStep">
            <div>
              <button
                class="secondary-btn"
                type="button"
                @click="prevStep"
                v-show="!isFirst"
              >
                Atrás
              </button>
            </div>
            <div>
              <button class="primary-btn" v-show="!isLast">Continuar</button>
              <button class="primary-btn" v-show="isLast">
                <span v-show="!isLoading">Enviar</span>
                <div v-show="isLoading" class="spinner"></div>
              </button>
            </div>
          </div>
        </transition-group>
      </Form>
    </div>
  </div>

  <PrivacyModal :show="modal.privacy" @close="modal.privacy = false" />
  <TermsAndConditionsModal :show="modal.terms" @close="modal.terms = false" />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { submitSimulatorForm } from '../services/index';
import { launchDefaultErrorNotification } from '../composables/utils';

export default {
  name: 'SimulatorForm',
  props: {
    steps: {
      type: Array,
      required: true,
    },
    campaignId: {
      type: [String, Number],
      required: false,
    },
  },
  components: {
    PrivacyModal: defineAsyncComponent(() =>
      import('@/components/PrivacyModal.vue'),
    ),
    TermsAndConditionsModal: defineAsyncComponent(() =>
      import('@/components/TermsAndConditionsModal.vue'),
    ),
    SummarySection: defineAsyncComponent(() =>
      import('@/components/SummarySection.vue'),
    ),
    CustomRadioButton: defineAsyncComponent(() =>
      import('@/components/CustomRadioButton.vue'),
    ),
    CustomInput: defineAsyncComponent(() =>
      import('@/components/CustomInput.vue'),
    ),
    CustomSelect: defineAsyncComponent(() =>
      import('@/components/CustomSelect.vue'),
    ),
    RGPD: defineAsyncComponent(() => import('@/components/RGPD.vue')),
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      productsURL: 'form-simulation.php',
      currentStep: 0,
      stepCount: 0,
      notificationErrorId: '',
      website: null,
      spamTimer: null,
      isLoading: false,
      isSpam: true,
      rgpdAccepted: false,
      params: {},
      paramsAux: {
        applicant: {
          applicant_status_id: 1,
          campaign_id: this.campaignId,
          comments: '',
          utmSource: ''
        },
        collateral: {
          country_id: 1,
        },
      },
      modal: {
        privacy: false,
        terms: false,
      },
    };
  },
  computed: {
    isFirst() {
      return this.currentStep === 0;
    },
    isLast() {
      return this.currentStep === this.stepCount - 1;
    },
    fieldsWithComments() {
      return this.steps.flatMap((s) =>
        s.components.filter((c) => c.commentOutputVar),
      );
    },
  },
  mounted() {
    this.stepCount = this.steps.length;
    this.initFormParams();
    this.setSpamTimer();
  },
  methods: {
    setRGPD(value) {
      this.rgpdAccepted = value;
    },
    onUpdated(event) {
      const [parent, property] = event.target.name.split('.');

      if (!this.paramsAux[parent]) this.paramsAux[parent] = {};

      if (!property) {
        if (event.target.cleave) {
          this.paramsAux[parent] = event.target.cleave.getRawValue();
        } else {
          this.paramsAux[parent] = event.target.value;
        }

        return;
      }

      if (event.target.cleave) {
        this.paramsAux[parent][property] = event.target.cleave.getRawValue();
      } else {
        this.paramsAux[parent][property] = event.target.value;
      }
    },
    prevStep() {
      if (this.currentStep <= 0) return;

      if (this.notificationErrorId) notify.close(this.notificationErrorId);

      this.currentStep -= 1;
    },
    addCommentsToPayload() {
      this.paramsAux.applicant.comments = '';
      const separator = this.fieldsWithComments.length > 1 ? ' | ' : '';

      this.fieldsWithComments.forEach((c) => {
        this.paramsAux.applicant.comments += `${c.commentOutputVar}${
          this.params[c.outputVar]
        }${separator}`;
      });
    },
    async nextStep() {
      if (this.currentStep === this.stepCount - 1 && !this.isLoading) {
        // honeypot
        if (this.website) this.$router.push(this.productsURL);

        if (this.isSpam) {
          clearTimeout(this.spamTimer);
          return;
        }

        if (
          parseFloat(this.paramsAux.application.deposit_amount) >=
          parseFloat(this.paramsAux.application.collateral_price)
        ) {
          notify({
            title:
              'El valor de la aportación es mayor o igual al valor de la vivienda.',
            text: 'Modifícalo para poder continuar.',
            type: 'error',
          });
          return;
        }

        this.isLoading = true;
        this.addCommentsToPayload();

        try {
          const data = await submitSimulatorForm({...this.paramsAux, applicant: {...this.paramsAux.applicant, utmSource: localStorage.getItem("utmSource")}});

          if (!data.success) {
            if (data.error === 'applicant-exists') {
              if (!this.notificationErrorId) {
                this.notificationErrorId = Date.now();
              }

              notify({
                id: this.notificationErrorId,
                title:
                  'Ya tenemos una solicitud abierta con esta cuenta de correo.',
                text: 'Contáctanos para que te informemos de su estado<br> <b><a href="tel:+34602259415">602 25 94 15</a></b>.',
                type: 'error',
                duration: -1,
              });
            } else {
              launchDefaultErrorNotification(notify);
            }
            return;
          }

          sessionStorage.setItem('path', JSON.stringify(this.$route.path));
          sessionStorage.setItem('loanId', data.loan_id);
          sessionStorage.setItem('formData', JSON.stringify(this.params));
          sessionStorage.setItem(
            'submittedData',
            JSON.stringify(this.paramsAux),
          );

          this.sendConversion();

          this.$router.push(this.productsURL);
        } catch (error) {
          launchDefaultErrorNotification(notify);
        } finally {
          this.isLoading = false;
        }
      }

      if (this.currentStep < this.stepCount - 1) this.currentStep += 1;
    },
    initFormParams() {
      const formData = sessionStorage.getItem('formData');
      const submittedData = sessionStorage.getItem('submittedData');

      if (formData && submittedData) {
        this.params = JSON.parse(formData);
        this.paramsAux = JSON.parse(submittedData);
      }

      this.steps
        .flatMap((step) => step.components)
        .forEach((component) => {
          if (!(component.outputVar in this.params)) {
            this.params[component.outputVar] = '';
          }
        });
    },
    setSpamTimer() {
      this.spamTimer = setTimeout(() => {
        this.isSpam = false;
      }, 3000);
    },
    sendConversion() {
      if (process.env.NODE_ENV === 'production') {
        this.$gtag.query('event', 'conversion', {
          send_to: `${process.env.VUE_APP_GADS_ID}/${process.env.VUE_APP_GADS_STEPPER}`,
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-section {
  margin: 20px auto;

  input[name='website'],
  .website-validation-message {
    display: none;
  }
}

.list-enter-from {
  opacity: 0;
}

.list-enter-to {
  opacity: 1;
}

.list-enter-active {
  transition: all 1s ease;
}

.list-move {
  transition: all 0.4s ease;
}

.list-leave-active,
.step-buttons.list-leave-active {
  display: none;
}

.step-buttons {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border-left-color: white;
  margin: 0 auto;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.checkbox-container {
  text-align: left;
  margin-top: 20px;
}

.privacy-policy,
.terms-conditions {
  text-align: left;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
}
</style>
