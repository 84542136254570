import { defineRule } from 'vee-validate';
import { createApp } from 'vue';
import Cleave from 'cleave.js';
import Notifications from '@kyvg/vue3-notification';
import VueGtag from 'vue-gtag-next';
import App from './App.vue';
import router from './router';

require('cleave.js/dist/addons/cleave-phone.es');

const app = createApp(App);

app.directive('cleave', {
  mounted: (el, binding) => {
    const element = el;
    element.cleave = new Cleave(el, binding.value || {});
  },
  updated: (el) => {
    const event = new Event('input', { bubbles: true });
    setTimeout(() => {
      const element = el;
      element.value = el.cleave.properties.result;
      element.dispatchEvent(event);
    }, 100);
  },
});

defineRule('required', (value) => {
  const val =
    typeof value === 'string' || value instanceof String || value === undefined
      ? value
      : value.toString();

  if (!val || !val.length) {
    return 'Este campo es requerido';
  }

  return true;
});

defineRule('email', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  // Check if email
  if (!/[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}/.test(value)) {
    return 'El formato del email es incorrecto';
  }

  return true;
});

defineRule('phone', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  const phone = value.toString().replace(/\s/g, '');

  // Check if phone
  if (phone.length !== 9 || !/^[679]{1}[0-9]{8}$/.test(phone)) {
    return 'El formato del teléfono es incorrecto';
  }

  return true;
});

defineRule('minLength', (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }

  if (value.length < limit) {
    return `Este campo tiene que tener ${limit} caracteres como mínimo`;
  }

  return true;
});

defineRule('maxLength', (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }

  if (value.length > limit) {
    return `Este campo tiene que tener ${limit} caracteres como máximo`;
  }

  return true;
});

defineRule('minAmount', (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }

  if (value.indexOf(',') > -1) {
    return 'No introduzcas decimales';
  }

  const val = value.replace(/\./g, '');

  if (!/^\d+$/.test(val) || Number.isNaN(parseFloat(val))) {
    return 'Tienes que introducir números';
  }

  if (parseFloat(val) < limit) {
    return `La cantidad mínima es de ${limit}€`;
  }

  return true;
});

defineRule('rgpdChecked', (value) => {
  if (!value) {
    return 'Tienes que aceptar la política de privacidad y los términos y condiciones';
  }

  return true;
});

if (process.env.NODE_ENV === 'production') {
  const gTagConfig = {
    property: [
      {
        id: process.env.VUE_APP_GTAG_ID,
        default: true,
      },
      {
        id: process.env.VUE_APP_GADS_ID,
      },
    ],
  };

  app.use(VueGtag, gTagConfig);
}

app
  .use(Notifications)
  .use(router)
  .mount('#app');
