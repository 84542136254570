<template>
  <div class="container">
    <div class="section is-medium has-text-centered">
      <div class="mt-6">
        <img class="logo" src="/img/logos/finteca-logo.svg" />
      </div>
      <div>
        <p class="is-size-3 mt-6">¡Oops!</p>
        <p class="error-code">404</p>
        <p class="has-text-weight-medium">Esta página no existe</p>
      </div>
      <div class="mt-6 button-row">
        <a
          rel="noopener noreferrer"
          href="https://www.finteca.es/"
          class="primary-btn"
          >Home</a
        >
      </div>
      <p class="mt-6">O contacta con nosotros:</p>
      <nav class="level mt-6">
        <div class="level-item has-text-centered">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              fill="#333333"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <path
                d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216,136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z"
                fill="none"
                stroke="#333333"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></path>
            </svg>
            <p class="subtitle">
              <a :href="'tel:+34602251618'"> 602 25 16 18</a>
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              fill="#333333"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <path
                d="M45.4,177A95.9,95.9,0,1,1,79,210.6h0L45.8,220a7.9,7.9,0,0,1-9.8-9.8L45.4,177Z"
                fill="none"
                stroke="#333333"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></path>
              <path
                d="M152.1,184A79.9,79.9,0,0,1,72,103.9,28,28,0,0,1,100,76h0a6.8,6.8,0,0,1,6,3.5l11.7,20.4a8.1,8.1,0,0,1-.1,8.1l-9.4,15.7h0a48,48,0,0,0,24.1,24.1h0l15.7-9.4a8.1,8.1,0,0,1,8.1-.1L176.5,150a6.8,6.8,0,0,1,3.5,6h0A28.1,28.1,0,0,1,152.1,184Z"
                fill="none"
                stroke="#333333"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></path>
            </svg>
            <p class="subtitle">
              <a rel="noopener noreferrer" :href="'https://wa.me/34602251618'"
                >602 25 16 18</a
              >
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              fill="#333333"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <circle
                cx="128"
                cy="128"
                r="40"
                fill="none"
                stroke="#333333"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></circle>
              <path
                d="M181.1,208A96,96,0,1,1,224,128c0,22.1-8,40-28,40s-28-17.9-28-40V88"
                fill="none"
                stroke="#333333"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></path>
            </svg>
            <p class="subtitle">
              <a :href="'mailto:hola@finteca.es'"> hola@finteca.es</a>
            </p>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.container {
  .logo {
    max-width: 200px;
    height: auto;
  }

  .primary-btn {
    background-color: #3269fc;
    border: 1px solid #3269fc;
  }

  .section {
    padding: 3rem 1.5rem;
  }

  .has-text-centered {
    text-align: center !important;
  }

  @media screen and (min-width: 1024px) {
    .section.is-medium {
      padding: 3rem 4.5rem;
    }
  }

  .is-size-3 {
    font-size: 1.5rem !important;
    margin-top: 0;
  }

  .error-code {
    font-size: 6rem;
    margin: 0;
  }

  .has-text-weight-medium {
    font-weight: 500 !important;
  }

  .mt-6 {
    margin-top: 2rem !important;
  }

  .button-row {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .level {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 767.98px) {
    .level {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }

  .button-row {
    button,
    a {
      width: 104.42px;
    }
  }
}
</style>