<template>
  <Header></Header>

  <div class="home" v-if="appCanWork">
    <SimulatorForm :steps="config.steps" :campaignId="config.campaignId" />
    <BankList />
    <FooterLogo v-if="organization"/>
  </div>
  <CookiesWarning v-else />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useRoute } from 'vue-router';
import SimulatorForm from '@/components/SimulatorForm.vue';
import { importCssFile } from '@/composables/utils';
import {forms }  from '../../config';


export default {
  name: 'Simulator',
  components: {
    SimulatorForm,
    Header: defineAsyncComponent(() => import('@/components/Header.vue')),
    FooterLogo: defineAsyncComponent(() => import('@/components/FooterLogo.vue')),
    BankList: defineAsyncComponent(() => import('@/components/BankList.vue')),
    CookiesWarning: defineAsyncComponent(() =>
      import('@/components/CookiesWarning.vue'),
    ),
  },
  data() {
    return {
      appCanWork: true,
      organization: null,
    };
  },
  computed: {
    config() {
      return forms.find((f) => f.form.includes('stepper'));
    },
  },
  mounted() {
    try {
      if (typeof sessionStorage !== 'undefined') {
        const route = useRoute();
        sessionStorage.setItem('queryParams', JSON.stringify(route.query));

        if(route.query.utm_source) {
          localStorage.setItem('utmSource', route.query.utm_source);
        }
        const storageParams = JSON.parse(sessionStorage.getItem('queryParams'))
        let cssFile = this.config.cssFilename

        if(storageParams.organization) {
          const currentForm = forms.find((f) => f.form.includes(`organization=${storageParams.organization}`));
          if(currentForm && currentForm.cssFilename) {
            this.organization = currentForm
            cssFile = currentForm.cssFilename
          }
        }

        importCssFile(cssFile);
        sessionStorage.setItem('cssFilename', cssFile);

      }
    } catch (error) {
      this.appCanWork = false;
    }
  },
};
</script>

