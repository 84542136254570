<template>
  <router-view />
  <notifications
    position="top right"
    :duration="10000"
    :closeOnClick="false"
    :ignoreDuplicates="true"
  />
</template>

<style lang="scss">
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #333333;
  opacity: 1; /* Firefox */
  font-size: 16px;
  font-family: $primary-font, Helvetica, Arial, sans-serif;
  font-weight: 500;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #333333;
  font-size: 16px;
  font-family: $primary-font, Helvetica, Arial, sans-serif;
  font-weight: 500;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #333333;
  font-size: 16px;
  font-family: $primary-font, Helvetica, Arial, sans-serif;
  font-weight: 500;
}

select {
  font-size: 16px !important;
}

#app {
  font-family: $primary-font, Helvetica, Arial, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-color;
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px !important;
  }
}

.container {
  margin: auto;
  overflow-x: hidden;

  @media (min-width: 768px) {
    width: 90%;
    max-width: 1140px;
  }
}

span[role='alert'] {
  display: block;
  color: #b82e24;
  text-align: left;
  font-size: 12px;
  margin: 5px 0;
  flex-grow: 2;
  width: 100%;

  @media (min-width: 768px) {
    font-size: 16px !important;
  }
}

.content-container {
  padding: 0 30px;

  &.content-iframe {
    padding: 0;
  }

  @media (min-width: 768px) {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}

.component-container {
  padding: 8px 0;
}

.primary-btn {
  border-radius: 6px;
  text-align: center;
  color: white;
  // padding: 9px 32px;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  font-family: $primary-font, Helvetica, Arial, sans-serif;
  font-size: 14px;
  width: 127px;
  height: 40px;
  text-decoration: none;
  transition: all 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  &:hover {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  }

  @media (min-width: 992px) {
    width: 136px !important;
    font-size: 16px;
  }
}

.secondary-btn {
  border-radius: 6px;
  text-align: center;
  background-color: white;
  padding: 9px 32px;
  outline: none;
  cursor: pointer;
  font-family: $primary-font, Helvetica, Arial, sans-serif;
  font-size: 14px;
  width: 127px;
  height: 40px;
  text-decoration: none;
  transition: all 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  &:hover {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  }

  @media (min-width: 992px) {
    width: 136px !important;
    font-size: 16px;
  }
}

.btn-block {
  display: block;
  width: 100% !important;
}

.close-modal-btn {
  border-radius: 4px;
  text-align: center;
  padding: 9px 32px;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  font-family: $primary-font, Helvetica, Arial, sans-serif;
  font-size: 14px;
  width: 127px;
  height: 40px;
  text-decoration: none;
  transition: all 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  &:hover {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  }

  @media (min-width: 768px) {
    width: 136px !important;
    font-size: 16px;
  }
}

.m0 {
  margin: 0;
}

.mb5 {
  margin-bottom: 5px;
}

.font-weight-bold {
  font-weight: bold;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;

  &:hover {
    box-shadow: none !important;
  }
}

.vue-notification {
  font-size: 16px;
  margin: 20px;
  padding: 15px;

  .notification-content {
    margin-top: 10px;
  }

  a {
    color: white;
  }
}

.center-modal {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.columns {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
  // justify-content: center;

  &:not(:last-child) {
    margin-bottom: calc(1.5rem - 0.75rem);
  }

  .column {
    padding: 0.75rem;
    position: relative;
    width: 100%;
    @media (min-width: 768px) {
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;

      &.is-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }

      &.is-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
}

.content-info {
  padding-top: 24px;

  p,
  h1 {
    text-align: left;
  }

  h1 {
    font-weight: 600;

    @media (max-width: 767.98px) {
      text-align: center;
      margin-bottom: 32px;
    }
  }

  .logo {
    width: 100%;
    max-width: 200px;
    height: auto;
  }

  .img {
    margin-top: 24px;
    width: 100%;

    @media (max-width: 767.98px) {
      display: none;
    }
  }

  .content-list {
    display: flex;
    gap: 10px;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.form-container {
  background-color: #fbce5d;
  padding: 1rem;
  border-radius: 4px;

  @media (max-width: 767.98px) {
    border-radius: 0;
  }
}
</style>
