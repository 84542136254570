<template>
  <Header></Header>
  <div  v-if="data.products.loan">
    <ProductMocksList
      :loan="data.products.loan"
      :products="data.products.products"
    />
    <BankListResults />
    <FooterLogo v-if="data.organization"/>
  </div>
</template>

<script>
import { reactive, defineAsyncComponent } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import ProductMocksList from '@/components/ProductMocksList.vue';
import { getProductsByLoan } from '../services/index';
import {
  importCssFile,
  launchDefaultErrorNotification
} from '@/composables/utils';

export default {
  name: 'ProductsMock',
  components: {
    ProductMocksList,
    Header: defineAsyncComponent(() => import('@/components/Header.vue')),
    FooterLogo: defineAsyncComponent(() => import('@/components/FooterLogo.vue')),
    BankListResults: defineAsyncComponent(() => import('@/components/BankListResults.vue')),
  },
  setup() {
    const loanId = sessionStorage.getItem('loanId');
    const data = reactive({
      products: [],
      organization: false
    });
    const cssFilename = sessionStorage.getItem('cssFilename');

    const storageParams = JSON.parse(sessionStorage.getItem('queryParams'))
    if(storageParams.organization) {
      data.organization =  true
    }


    importCssFile(cssFilename);

    const getTae = (tin) =>{
      const tae = (1 + (tin / 100) / 12) ** 12 - 1
      const finalTae = (tae * 100).toFixed(2)
      return `${finalTae}%`
    }

    const getPayment = (loan, tin) => {
      const { collateralPrice , maturity, depositAmount } = loan
      const installments = 12 * maturity
      const capital = collateralPrice - depositAmount
      const montlyInt = tin/12

      const tinPercent = montlyInt/100
      const formulaUpperHalf = ((1+tinPercent)**installments) * tinPercent
      const formulaLowerHalf =  ((1+tinPercent)**installments) -1
      const result = capital * (formulaUpperHalf/formulaLowerHalf)

      return Math.ceil(result)
    }



    getProductsByLoan(loanId).then(
      (res) => {
        if (!res.success) {
          launchDefaultErrorNotification(notify);
        }
        const euribor = 3.678
        const productsMock = {
          "mock": [
              {
                  "id": 11,
                  "regime": "Fixed",
                  "name": "Hipoteca Fija",
                  "rates": {
                      "tae": getTae(2.4),
                      "tin": [
                          "2,40%"
                      ],
                      "payment": getPayment(res.loan,2.4),
                      "indebtedness": 19
                  },
                  "outboundUrl": null
              },
              {
                  "id": 12,
                  "regime": "Variable",
                  "name": "Hipoteca Mixta",
                  "rates": {
                      "tae": getTae(2.2),
                      "tin": [
                          "2,20% (5 A\u00f1os)",
                          `TIN 0,48% + EURIBOR (${euribor}%)`
                      ],
                      "payment": getPayment(res.loan, 2.2),
                      "indebtedness": 16
                  },
                  "outboundUrl": null
              },
              {
                  "id": 13,
                  "regime": "Fixed",
                  "name": "Hipoteca Variable",
                  "rates": {
                      "tae": getTae(euribor + 0.5),
                      "tin": [
                          `0.5% + EURIBOR (${euribor}%)`
                      ],
                      "payment": getPayment(res.loan, euribor + 0.5),
                      "indebtedness": 23
                  },
                  "outboundUrl": null
              }
          ],
        }
        data.products = res;
        data.products.products = productsMock
      },
      () => {
        notify({
          title: '¡Ops! Ha ocurrido un error inesperado.',
          text: 'Nos puedes contactar en: <b><a href="mailto:hola@finteca.es">hola@finteca.es</a></b>.',
          type: 'error',
        });
      },
    );

    return {
      data,
    };
  },
};
</script>
