import fintecaProdForms from './finteca/production/forms.json'
import fintecaProdPersonalization from './finteca/production/personalization.json'

import fintecaStagingForms from './finteca/staging/forms.json'
import fintecaStagingPersonalization from './finteca/staging/personalization.json'

import fintecaLocalForms from './finteca/development/forms.json'
import fintecaLocalPersonalization from './finteca/development/personalization.json'

import prohipotecasProdForms from './prohipotecas/production/forms.json'
import prohipotecasProdPersonalization from './prohipotecas/production/personalization.json'

import prohipotecasStagingForms from './prohipotecas/staging/forms.json'
import prohipotecasStagingPersonalization from './prohipotecas/staging/personalization.json'

import prohipotecasLocalForms from './prohipotecas/development/forms.json'
import prohipotecasLocalPersonalization from './prohipotecas/development/personalization.json'


const config = {
    "finteca": {
        "forms": {
            "production": fintecaProdForms,
            "staging": fintecaStagingForms,
            "development": fintecaLocalForms
        },
        "personalization": {
            "production": fintecaProdPersonalization,
            "staging": fintecaStagingPersonalization,
            "development": fintecaLocalPersonalization
        }
    },
    "prohipotecas": {
        "forms": {
            "production": prohipotecasProdForms,
            "staging": prohipotecasStagingForms,
            "development": prohipotecasLocalForms
        },
        "personalization": {
            "production": prohipotecasProdPersonalization,
            "staging": prohipotecasStagingPersonalization,
            "development": prohipotecasLocalPersonalization
        }
    }
}


const getSiteForms = () => {
    const { VUE_APP_SITE, NODE_ENV } = process.env

    if (config[VUE_APP_SITE] && config[VUE_APP_SITE].forms && config[VUE_APP_SITE].forms[NODE_ENV]) {
        return config[VUE_APP_SITE].forms[NODE_ENV]
    }

    return fintecaLocalForms
}


const getSitePersonalization = () => {
    const { VUE_APP_SITE, NODE_ENV } = process.env

    if (config[VUE_APP_SITE] && config[VUE_APP_SITE].personalization && config[VUE_APP_SITE].personalization[NODE_ENV]) {
        return config[VUE_APP_SITE].personalization[NODE_ENV]
    }

    return fintecaLocalPersonalization
}

const forms = getSiteForms()
const personalization = getSitePersonalization()

export { forms, personalization }

