<template>
  <div class="footer">
    <div class="container">
      <div class="columns">
        <div class="column is-6">
          <img class="logo" src="/img/logos/finteca-logo.svg" />
          <p class="info">
            Nos encargamos de todos los tramites de tu hipoteca, de principio a
            fin. Finteca solamente cobra la comisión al banco por lo tanto, te
            asesoramos de forma totalmente gratuita para ti.
          </p>
        </div>
        <div class="column is-3">
          <p class="title">Contacta con nosotros</p>
          <p>Plaça de Pau Vila nº1, Esc A, 2-1 08039 Barcelona</p>
          <p>
            <a href="mailto: solicita@finteca.es" class="cursor-pointer"
              >solicita@finteca.es</a
            >
          </p>
          <p>
            <a href="tel:34602259415">+34 602 259 415</a>
          </p>
        </div>
        <div class="column is-3">
          <p class="title">Legal</p>
          <p>
            <a
              href="https://www.finteca.es/legal/legal.html"
              target="_blank"
              class="cursor-pointer"
              >Condiciones de contratación</a
            >
          </p>
          <p>
            <a
              href="https://www.finteca.es/legal/legal.html#privacyPolicy"
              target="_blank"
              class="cursor-pointer"
              >Pólitica de privacidad</a
            >
          </p>
          <p>
            <a
              href="https://www.finteca.es/legal/legal.html"
              target="_blank"
              class="cursor-pointer"
              >Política de cookies</a
            >
          </p>
        </div>
      </div>
      <div>
        <p class="legal">
          Finteca Tech S.L. no garantiza la obtención de ningún préstamo
          financiero ni de otra clase al usuario, tan solo ofrece simulaciones.
          El usuario asume toda la responsabilidad en la valoración y
          utilización de los servicios, información, datos, archivos, productos
          y cualquier clase de material proporcionado por Finteca Tech S.L. Como
          todos los servicios de Finteca Tech S.L. este informe está sujeto a
          los términos y condiciones de Finteca Tech S.L.:
          <a href="https://www.finteca.es/legal/legal.html" target="_blank"
            >www.finteca.es/legal/legal.html</a
          >
        </p>
      </div>
      <div class="copyright-section">
        <p>Finteca Tech S.L. Copyright © {{ year }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  padding: 48px 0 24px 0;
  background: #f4f4f4;

  @media (max-width: 767.98px) {
    padding: 48px 30px 24px 30px;
  }

  .title {
    color: #444;
    font-weight: 600;
    font-size: 16px;

    @media (max-width: 767.98px) {
      margin-top: 0;
    }
  }

  p {
    color: #888;
    font-weight: 400;

    &.text-left {
      text-align: left;
    }
  }

  .logo {
    width: 100%;
    max-width: 215px;
    height: auto;
  }

  .column {
    text-align: left;
  }

  .legal,
  .info {
    font-size: 14px;
    text-align: left;
  }

  .copyright-section {
    p {
      margin-top: 24px;
    }
  }

  @media (max-width: 767.98px) {
    .column {
      padding-bottom: 0;
    }
  }
}
</style>