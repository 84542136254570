const toSpanishFormat = (value) => value.toLocaleString('es-AR');

const importCssFile = (filename) => {
  const element = document.createElement('link');
  element.setAttribute('rel', 'stylesheet');
  element.setAttribute('type', 'text/css');
  element.setAttribute('href', `/css/${filename}?v=${new Date().getTime()}`);
  document.getElementsByTagName('head')[0].appendChild(element);
};

const launchDefaultErrorNotification = (notify) => {
  notify({
    title: '¡Ops! Ha ocurrido un error inesperado.',
    text:
      'Nos puedes contactar en: <b><a href="mailto: hola@finteca.es">hola@finteca.es</a></b>.',
    type: 'error',
  });
};

export { toSpanishFormat, importCssFile, launchDefaultErrorNotification};
