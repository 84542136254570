<template>
  <Header></Header>
  <div class="body">
    <div class="container">
      <div class="columns" v-if="appCanWork">
        <div class="column is-6">
          <div class="content-container">
            <component
              :is="landing.contentComponent"
              :content="landing.content"
            ></component>
          </div>
        </div>
        <div class="column is-6">
          <div class="form-container single-form">
            <h3 style="margin-bottom: 0">
              Completa la siguiente información y te mostraremos las cuotas en
              varias entidades
            </h3>
            <SingleForm
              :fields="config.fields"
              :campaignId="landing.campaignId"
              :googleAdsId="landing.googleAdsId"
            />
          </div>
        </div>
      </div>
      <CookiesWarning v-else />
    </div>
  </div>

  <Footer></Footer>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useRoute } from 'vue-router';
import SingleForm from '@/components/SingleForm.vue';
import Footer from '@/components/Footer.vue';
import { importCssFile } from '@/composables/utils';
import {forms, personalization }  from '../../config';

export default {
  name: 'SingleFormSimulator',
  components: {
    SingleForm,
    Header: defineAsyncComponent(() => import('@/components/Header.vue')),
    CookiesWarning: defineAsyncComponent(() =>
      import('@/components/CookiesWarning.vue'),
    ),
    ContentText: defineAsyncComponent(() =>
      import('@/components/ContentText.vue'),
    ),
    ContentList: defineAsyncComponent(() =>
      import('@/components/ContentList.vue'),
    ),
    Footer,
  },
  data() {
    return {
      appCanWork: true,
      // Remove / at the end if exists
      path: this.$route.path.replace(/\/$/, ''),
    };
  },
  computed: {
    config() {
      return forms.find((f) => f.form.includes(this.path));
    },
    landing() {
      return personalization.find((p) => p.route === this.path);
    },
  },
  mounted() {
    try {
      if (typeof sessionStorage !== 'undefined') {
        const route = useRoute();

        sessionStorage.setItem('queryParams', JSON.stringify(route.query));
        sessionStorage.setItem('cssFilename', this.config.cssFilename);

        if(route.query.utm_source) {
          localStorage.setItem('utmSource', route.query.utm_source);
        }

        importCssFile(this.config.cssFilename);
      }
    } catch (error) {
      this.appCanWork = false;
    }
  },
};
</script>

<style scoped lang="scss">
.body {
  background-image: linear-gradient(#fff, #f4f4f4);

  .container {
    padding-top: 2.5rem;

    @media (max-width: 767.98px) {
      padding-top: 0;
    }
  }
}
</style>
