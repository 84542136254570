import { createRouter, createWebHistory } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';

import Simulator from '../views/Simulator.vue';
import SingleFormSimulator from '../views/SingleFormSimulator.vue';
import ProductsMock from '../views/ProductsMock.vue';
import NotFound from '../views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Simulator',
    component: Simulator,
    meta: {
      title: 'Simulador de hipotecas',
      keepAlive: true,
    },
  },
  {
    path: '/hipoteca-100',
    alias: [
      '/hipoteca-100',
      '/calcula-cuota-hipoteca',
      '/hipoteca-sin-vinculaciones',
      '/simular-hipoteca',
      '/simulador-hipoteca-santander',
      '/simular-hipoteca-bankinter',
      '/simular-hipoteca-bbva',
      '/simular-hipoteca-ing',
      '/simular-hipoteca-liberbank',
      '/simular-hipoteca-sabadell',
      '/simular-hipoteca-prestalo',
    ],
    name: 'SingleFormSimulator',
    component: SingleFormSimulator,
    meta: {
      title: 'Simulador de hipotecas',
      keepAlive: true,
    },
  },
  {
    path: '/form-simulation.php',
    name: 'ProductsMock Stepper',
    component: ProductsMock,
    meta: {
      title: 'Productos hipotecarios',
    },
  },
  {
    path: '/:url/simulation.php',
    name: 'ProductsMock',
    component: ProductsMock,
    meta: {
      title: 'Productos hipotecarios',
    },
  },
  {
    path: '/:pathMatch(.*)',
    component: NotFound,
    name: 'NotFound',
    meta: {
      title: 'Esta página no existe',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === 'ProductsMock' || to.name === 'ProductsMock Stepper') {
    const loanId = sessionStorage.getItem('loanId');
    if (!loanId) {
      const path = sessionStorage.getItem('path') || '/';
      next({ path });
    }
  }

  document.title = `${to.meta.title}`;
  next();
});

if (process.env.NODE_ENV === 'production') {
  trackRouter(router);
}

export default router;
